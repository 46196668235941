"use client";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { LANGUAGES_COUNTRIES, LOCALES } from "@/lib/constants";
import { getCountryDataList } from "countries-list";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { useTranslations } from "next-intl";
import { FC } from "react";
import { LanguageCode } from "../../../types/language";

type SelectLanguageProps = {
  selectedLanguage?: LanguageCode;
  onSelectLanguage: (language: LanguageCode) => void;
  shortNames?: boolean;
};

const SelectLanguage: FC<SelectLanguageProps> = ({
  selectedLanguage,
  onSelectLanguage,
  shortNames,
}) => {
  const t = useTranslations();
  const countryList = getCountryDataList().filter((country) =>
    LANGUAGES_COUNTRIES.includes(country.iso2)
  );
  return (
    <Select onValueChange={onSelectLanguage} value={selectedLanguage}>
      <SelectTrigger className="w-fit">
        <SelectValue placeholder={t("SelectLanguage.placeholder")} />
      </SelectTrigger>
      <SelectContent>
        {LOCALES.map((locale) => {
          const country = countryList.find((c) => c.languages[0] === locale);
          const hasFlagIcon = country && hasFlag(country.iso2);
          return (
            <SelectItem key={locale} value={locale}>
              <div className="flex items-center justify-center space-x-1">
                {hasFlagIcon && <span>{getUnicodeFlagIcon(country.iso2)}</span>}
                <span>
                  {shortNames
                    ? locale.toUpperCase()
                    : t(`Languages.${locale}` as any)}
                </span>
              </div>
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};

export default SelectLanguage;
