"use client";

import { SigninSchema } from "@/app/[locale]/signin/_scheme/signin";
import SelectLanguage from "@/components/form/select-language";
import Logo from "@/components/logo";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useTitlePage } from "@/hooks/use-title-page";
import { toast } from "@/hooks/use-toast";
import { Link } from "@/i18n/routing";
import { changeLanguageUI } from "@/lib/utils";
import { zodResolver } from "@/lib/zod";
import { useLocale, useTranslations } from "next-intl";
import { useTransition } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { LanguageCode } from "../../../../types/language";
import { login } from "./actions";

const Page = () => {
  useTitlePage();
  const t = useTranslations();
  const locale = useLocale();
  const [isPending, startTransition] = useTransition();
  const form = useForm<z.infer<typeof SigninSchema>>({
    resolver: zodResolver(SigninSchema),
    defaultValues: {
      email: "",
    },
  });

  function onSubmit(values: z.infer<typeof SigninSchema>) {
    startTransition(async () => {
      const [data, err] = await login(values);
      if (err)
        toast({
          title: t("Common.error"),
          description: err.message,
          variant: "destructive",
        });
      else {
        form.reset();
        toast({
          title: t("SigninPage.form.success.login.title"),
          description: t.rich("SigninPage.form.success.login.description", {
            node0: (children) => <span className="font-bold">{children}</span>,
            email: values.email,
          }),
        });
      }
    });
  }
  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      <Logo />
      <Card className="w-full max-w-[350px]">
        <CardHeader>
          <CardTitle>{t("SigninPage.title")}</CardTitle>
          <CardDescription>{t("SigninPage.subtitle")}</CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              id="signin"
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("SigninPage.form.fields.email.label")}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button loading={isPending} form="signin" className="w-full">
            {t("SigninPage.submit")}
          </Button>
          <Button variant="link" asChild>
            <Link href="/signup">
              {t("SigninPage.form.actions.createAccount")}
            </Link>
          </Button>
        </CardFooter>
      </Card>
      <SelectLanguage
        selectedLanguage={locale as LanguageCode}
        onSelectLanguage={(locale) => changeLanguageUI(locale, "/signin")}
      />
    </div>
  );
};

export default Page;
