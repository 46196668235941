import { createNavigation } from "next-intl/navigation";
import {
  AppRouterInstance,
  NavigateOptions,
} from "next/dist/shared/lib/app-router-context.shared-runtime";
import * as NProgress from "nprogress";
import { useCallback, useEffect } from "react";
import { routing } from "./navigation";

export const { Link, usePathname } = createNavigation(routing);

const { useRouter: _useRouter } = createNavigation(routing);

export const useRouter = (): AppRouterInstance => {
  const router = _useRouter();
  const pathname = usePathname();
  useEffect(() => {
    NProgress.done();
  }, [pathname]);
  const replace = useCallback(
    (href: string, options?: NavigateOptions) => {
      href !== pathname && NProgress.start();
      router.replace(href, options);
    },
    [router, pathname]
  );

  const push = useCallback(
    (href: string, options?: NavigateOptions) => {
      href !== pathname && NProgress.start();
      router.push(href, options);
    },
    [router, pathname]
  );

  return {
    ...router,
    replace,
    push,
  };
};
