import { usePathname } from "@/i18n/routing";
import { APP_NAME } from "@/lib/constants";
import { useTranslations } from "next-intl";
import { useEffect } from "react";

export function useTitlePage() {
  const t = useTranslations("TitlePage");
  const pathname = usePathname();
  const title = t(pathname as any);
  useEffect(() => {
    document.title = `${
      process.env.NEXT_PUBLIC_APP_ENV == "test" ? "[TEST MODE] - " : ""
    }${APP_NAME} App | ${title}`;
  }, [title]);
  return { title, pathname };
}
