import { APP_NAME } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { FC } from "react";
import Image from "./image";

type LogoProps = {
  type?: "full" | "short";
  dark?: boolean;
  className?: string;
};

const Logo: FC<LogoProps> = ({
  type = "full",
  dark = false,
  className = "w-64",
}) => {
  return (
    <div>
      <Image
        src={`/images/logo/${type}/logo-${dark ? "dark" : "light"}.png`}
        className={cn(className)}
        width={200}
        height={10}
        alt={APP_NAME}
      />
    </div>
  );
};

export default Logo;
